import OrderRowItem from '../Order/Models/OrderRowItem.interface';
import { useTranslationData } from '../Shared/Providers/TranslationProvider';
import { styled } from '../stitches.config';
import Image from '../Atoms/Image/Image';
import CtaButton from '../Atoms/Buttons/CtaButton';
import QuantitySelector from '../Atoms/Quantity/QuantitySelector';
import { useState } from 'react';
import { UpdateCart } from '../Pages/CartPage/Cart';
import PageModelBase from '../Shared/Models/PageModelBase.interface';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import { useAppSettingsData } from '../Shared/Providers/AppSettingsProvider';
import { mediaQueryTypes } from '../Theme/Settings/mediaQueries';
import useMedia from '../Shared/Hooks/useMedia';

type PropType = {
  product: OrderRowItem;
};

function OrderListingProductCard({ product }: PropType) {
  const { languageRoute } = useAppSettingsData();
  const { channelId, pageId } = useCurrentPage<PageModelBase>();
  const {
    'productItem/size': sizeLabel,
    'productPage/addToCart': addToCartLabel,
  } = useTranslationData();
  const [quantity, setQuantity] = useState<number>(
    product.minimumSalesQuantity
  );
  const [isAdding, setIsAdding] = useState<boolean>(false);

  const litiumContext = JSON.stringify({
    currentPageSystemId: pageId,
    channelSystemId: channelId,
  });

  const isMobile = useMedia(mediaQueryTypes.mediaMaxSmall);

  function addToCart() {
    setIsAdding(true);
    UpdateCart(product.articleNumber, quantity, languageRoute, litiumContext)
      .then(() => setIsAdding(false))
      .catch(() => setIsAdding(false));
  }

  const onQuantityChange = (value: number) => {
    setQuantity(value);
  };

  const renderAddToCart = () => {
    return (
      <AddToCart>
        <QuantitySelector
          onChange={onQuantityChange}
          value={quantity?.toString()}
          minimumSalesQuantity={product.minimumSalesQuantity}
        />
        <AddToCartButton
          size={'s'}
          onClick={() => addToCart()}
          isLoading={isAdding}
        >
          {addToCartLabel}
        </AddToCartButton>
      </AddToCart>
    );
  };

  return (
    <Root>
      <ImageAndAttributeArea>
        <ImageWrapper>
          <StyledImage src={product.image?.src} alt={product.image?.alt} />
        </ImageWrapper>
        <AttributeArea>
          <Name>
            {product.quantityString} x {product.name}
          </Name>
          {product.size && (
            <Attribute>
              {sizeLabel}: {product.size}
            </Attribute>
          )}
          <PriceWrapper>
            <TotalPrice>{product.totalPrice}</TotalPrice>
          </PriceWrapper>
        </AttributeArea>
        {!isMobile && renderAddToCart()}
      </ImageAndAttributeArea>
      {isMobile && renderAddToCart()}
    </Root>
  );
}

const Root = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  py: 4,
  '&:not(:last-of-type)': {
    borderBottom: '1px solid $primary50opacity',
  },
});

const AddToCart = styled('div', {
  mt: 4,
  '@mediaMinSmall': {
    mt: 'auto',
  },

  h: '100%',
  maxW: 75,
  display: 'flex',
  pt: 0,
  w: '100%',
});

const AddToCartButton = styled(CtaButton, {
  flexBasis: '50%',
  h: 9,
});

const AttributeArea = styled('div', {
  ml: 4,
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
});

const ImageAndAttributeArea = styled('div', {
  display: 'flex',
});

const Name = styled('span', {
  fs: 7,
  fontWeight: '$fw700',
  mb: 1,
  lineHeight: '18px',
});

const Attribute = styled('span', {
  fs: 6,
  lineHeight: '18px',
});

const PriceWrapper = styled('div', {
  w: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  mt: 'auto',
});

const TotalPrice = styled('span', {
  fs: 6,
  fontWeight: '$fw700',
});

const ImageWrapper = styled('div', {
  h: 11.5,
  w: 8,
});

const StyledImage = styled(Image, {
  objectFit: 'contain',
  h: '100%',
});

export default OrderListingProductCard;
