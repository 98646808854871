import { mutate } from 'swr';
import {
  EventDispatcher,
  NOTIFY_ACTION,
} from '../Shared/Common/EventDispatcher';
import Fetcher from '../Shared/Common/Fetcher';

let abortController: AbortController = new AbortController();

export async function GetOrders(
  channelId: string,
  url: string,
  setIsLoading: (value: boolean) => void
) {
  setIsLoading(true);
  abortController.abort();
  abortController = new AbortController();
  const signal = abortController.signal;
  const litiumContext = JSON.stringify({
    channelSystemId: channelId,
  });
  return await Fetcher<any, any>(
    url,
    signal,
    (data, resolve) => {
      resolve(data);
      setIsLoading(false);
    },
    litiumContext
  );
}

export async function GetOrder(
  channelId: string,
  url: string,
  setIsLoading: (value: boolean) => void
) {
  setIsLoading(true);
  abortController.abort();
  abortController = new AbortController();
  const signal = abortController.signal;
  const litiumContext = JSON.stringify({
    channelSystemId: channelId,
  });
  return Fetcher<any, any>(
    url,
    signal,
    (data, resolve) => {
      resolve(data);
      setIsLoading(false);
    },
    litiumContext
  );
}

export async function ApproveOrder(
  channelId: string,
  languageRoute: string,
  orderId: string
) {
  const res = await fetch(
    `/api/${languageRoute || 'en'}/order/ApproveOrder?orderId=${orderId}`,
    {
      method: 'POST',
      headers: {
        'litium-request-context': JSON.stringify({
          channelSystemId: channelId,
        }),
      },
    }
  );
  const response = await res.json();
  return {
    status: res.status,
    orderStatus: response,
  };
}

export async function AddOrderLinesToCart(
  channelId: string,
  languageRoute: string,
  orderId: string,
  setIsLoading: (value: boolean) => void
) {
  setIsLoading(true);
  const res = await fetch(
    `/api/${
      languageRoute || 'en'
    }/order/AddOrderLinesToCart?orderId=${orderId}`,
    {
      method: 'POST',
      headers: {
        'litium-request-context': JSON.stringify({
          channelSystemId: channelId,
        }),
      },
    }
  );

  setIsLoading(false);

  if (res.ok) {
    const { cart, notification } = await res.json();
    // const response = await res.json();
    const cartUrl = `/api/${languageRoute || 'en'}/Cart/`;
    EventDispatcher.dispatch(NOTIFY_ACTION, notification);
    mutate(`${cartUrl}GetCart`, cart, false);
    return true;
  }
  return false;
}
